import { render, staticRenderFns } from "./ExtraInfoInnerSection.vue?vue&type=template&id=21713dfa&scoped=true&"
import script from "./ExtraInfoInnerSection.vue?vue&type=script&lang=js&"
export * from "./ExtraInfoInnerSection.vue?vue&type=script&lang=js&"
import style0 from "./ExtraInfoInnerSection.vue?vue&type=style&index=0&id=21713dfa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21713dfa",
  null
  
)

export default component.exports