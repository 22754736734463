(<template>
  <div class="post-form__section">
    <sk-note :text="vatInfo"
             class="post-assignment__note" />
  </div>
</template>)

  <script>

  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      vatInfo() {
        return this.$gettext('If a category containing the text "with a decision that includes interpreting" is selected, the assignment may be invoiced without value-added tax (VAT). An important condition for this is that the reference number of the decision is provided and that the decision can be presented to Salita for possible verification at a later date.');
      }
    },
  };
  </script>

<style scoped>
    .post-form__section {
      margin-bottom: 32px;
    }

    .post-assignment__note {
      width: 100%;
    }
  </style>
