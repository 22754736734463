export default (pgettext, context) => {
  const postAssignmentHelper = {
    interpretation: {
      realName: {
        title: pgettext('helper', 'Real name'),
        content: pgettext('helper', 'It looks like you are booking through a shared account. We need you to fill in your actual name, so that we know who has booked the assignment.'),
        readMoreLink: ''
      },
      bookerName: {
        title: pgettext('helper', 'Name of the booker'),
        content: pgettext('helper', 'Filled in from logged-in user by default. If you are booking for someone else, you can choose another booker by clicking Edit.'),
        readMoreLink: ''
      },
      contactInfo: {
        title: pgettext('helper', 'Contact info'),
        content: pgettext('helper', 'Booker\'s contact information. Filled in from logged-in user by default. If you are booking for someone else, you can choose another booker by clicking Edit.'),
        readMoreLink: ''
      },
      department: {
        title: pgettext('helper', 'Department'),
        content: pgettext('helper', 'Please choose which department you are booking for. If you primarily book only for one department, you can remove the other one so you don\'t have to choose every time.'),
        readMoreLink: ''
      },
      bookingReference: {
        title: pgettext('helper', 'Booking reference'),
        content: pgettext('helper', 'Booking Reference is used on the invoice to show where the booking is coming from. You can change this here if needed.'),
        readMoreLink: ''
      },
      paymentBookingReference: {
        title: pgettext('helper', 'Payment booking reference'),
        content: pgettext('helper', 'Payment booking reference is used on the invoice to show which department or enterprise the booking is connected to. You can change this here if needed.'),
        readMoreLink: ''
      },
      caseNumber: {
        title: pgettext('helper', 'Case number'),
        content: pgettext('helper', 'Case number refers to the case number linked to the order. You can change here if necessary.'),
        readMoreLink: ''
      },
      paymentMethod: {
        title: pgettext('helper', 'Payment method'),
        content: pgettext('helper', 'Information about where Salita may send the invoice after the assignment is finished. You can change or add invoice information by clicking Change.'),
        readMoreLink: ''
      },
      dialect: {
        title: pgettext('helper', 'Dialect'),
        content: pgettext('helper', 'Do you need the interpreter to be proficient in a specific dialect? Please fill it in here.'),
        readMoreLink: ''
      },
      dateTime: {
        title: pgettext('helper', 'Date and time'),
        content: pgettext('helper', 'Date, start time and end time for when the assignment will take place.'),
        readMoreLink: ''
      },
      category: {
        title: pgettext('helper', 'Category'),
        content: pgettext('helper', 'Category refers to the type of assignment being booked – for example, a criminal case, healthcare agreement, or social assignment <br class="helper-margin-bottom" /> If you select a category that contains the text "with a decision that includes interpreting", the assignment may be invoiced without value-added tax (VAT). An important condition for this is that the reference number of the decision is provided and that the decision can be presented to Salita for possible verification at a later time. <br class="helper-margin-bottom" /> Section 3-4, first paragraph, letter a of the VAT Act exempts social services from VAT, including social services under the Health and Care Services Act and the Child Welfare Act. According to the Norwegian Tax Administration\'s policy statement of 09.12.24, interpreting services in connection with social services are only exempt from VAT if they are provided on the basis of a specific decision that includes interpreting services. This requires documentation in the form of a decision reference number.<br> You can find more information in the Tax Administration\'s statement here:'),
        readMoreLink: 'https://www.skatteetaten.no/rettskilder/type/uttalelser/prinsipputtalelser/avgiftsbehandlingen-ved-omsetning-av-tolketjenester-nar-hovedoppdragstaker-bruker-en-underoppdragstaker--merverdiavgiftsloven--3-2-forste-ledd-3-4-forste-ledd-og-3-5-andre-ledd/'
      },
      subject: {
        title: pgettext('helper', 'Subject'),
        content: pgettext('helper', 'Subject for the conversation, to give the interpreter some context. Make sure not to share sensitive information here, as the subject is visible to all invited interpreters.'),
        readMoreLink: ''
      },
      deadline: {
        title: pgettext('helper', 'Delivery deadline'),
        content: pgettext('helper', 'In a secretary assignment, this is the deadline for when the interpreter should have completed it at the latest.'),
        readMoreLink: ''
      },
      secretaryContactInfo: {
        title: pgettext('helper', 'Contact information and message'),
        content: pgettext('helper', 'Here you can insert your contact information, and add a note to the interpreter regarding the message to be delivered.'),
        readMoreLink: ''
      },
      secretaryCallbackInfo: {
        content: pgettext('helper', 'Tick this box and add your phone number to get a call back from the interpreter when they\'re done with the assignment.'),
        readMoreLink: ''
      },
      serialBookingDate: {
        title: pgettext('helper', 'Serial booking date and time'),
        content: pgettext('helper', 'Input the booking dates and times for the serial assignment.'),
        readMoreLink: ''
      },
      phoneAssignment: {
        title: pgettext('helper', 'Phone assignment'),
        content: pgettext('helper', 'For when you need an interpreter to interpret over the phone.'),
        readMoreLink: ''
      },
      inPersonAssignment: {
        title: pgettext('helper', 'In person assignment'),
        content: pgettext('helper', 'For when you need an interpreter to interpret in person.'),
        readMoreLink: ''
      },
      videoAssignment: {
        title: pgettext('helper', 'Video assignment'),
        content: pgettext('helper', 'For when you need an interpreter to interpret over video.'),
        readMoreLink: ''
      },
      videoRoomAssignment: {
        title: pgettext('helper', 'Video room assignment'),
        content: pgettext('helper', 'For when you need an interpreter to interpret in a video room.'),
        readMoreLink: ''
      },
      alternativeLanguage: {
        title: pgettext('helper', 'Alternative language'),
        content: pgettext('helper', 'An alternative to the language you have selected. Certain languages are harder to find an interpreter for than others. In these cases, it can be good to have an alternative language, if possible.'),
        readMoreLink: ''
      },
      messageToInterpreter: {
        title: pgettext('helper', 'Message to interpreter'),
        content: pgettext('helper', 'Write a message to the interpreter to provide more context, if needed. Please avoid any sensitive information.'),
        readMoreLink: ''
      },
      latestSuppliers: {
        title: pgettext('helper', 'Previously used interpreters'),
        content: pgettext('helper', 'Interpreters that have been booked previously by you or your department.'),
        readMoreLink: ''
      },
      severalSuppliers: {
        title: pgettext('helper', 'Several suppliers'),
        content: 'Content',
        readMoreLink: ''
      },
      directBooking: {
        title: pgettext('helper', 'Direct booking'),
        content: pgettext('helper', 'You can search for and choose a specific interpreter, if needed. Salita will invite this interpreter first.'),
        readMoreLink: ''
      },
      gender: {
        title: pgettext('helper', 'Gender'),
        content: pgettext('helper', 'If you need an interpreter of a specific gender, you can select this here.'),
        readMoreLink: ''
      },
      qualification: {
        title: pgettext('helper', 'Qualification'),
        content: pgettext('helper', 'If you need an interpreter with a specific IMDi qualification, you can select this here.'),
        readMoreLink: ''
      },
      blockedSuppliers: {
        title: pgettext('helper', 'Blocked suppliers'),
        content: pgettext('helper', 'Select this alternative if you need to block one or more specific interpreters from this assignment. The interpreter(s) will be blocked from this assignment, but not for any other assignments.'),
        readMoreLink: ''
      },
      blockedCities: {
        title: pgettext('helper', 'Blocked cities'),
        content: pgettext('helper', 'Select this alternative if you need to block interpreters from a specific geographical area, for example because of conflicts of interest.'),
        readMoreLink: ''
      },
      attachment: {
        title: pgettext('helper', 'Upload files'),
        content: pgettext('helper', 'You can upload files that you want the interpreter to look at before the assignment.'),
        readMoreLink: ''
      },
      participant: {
        title: pgettext('helper', 'Participant'),
        content: pgettext('helper', 'Do you need to send a copy of the assignment info to another participant who is not registered in the platform? You can add their email address and/or phone number, so that they will get the information about the assignment.'),
        readMoreLink: ''
      }
    },
    translation: {
      deadline: {
        title: pgettext('helper', 'Delivery deadline'),
        content: pgettext('helper', 'Deadline for the delivery of the translated files.'),
        readMoreLink: ''
      },
      targetLanguage: {
        title: pgettext('helper', 'Target language'),
        content: pgettext('helper', 'The language that you need the document translated into.'),
        readMoreLink: ''
      },
      document: {
        title: pgettext('helper', 'Documents'),
        content: pgettext('helper', 'Upload the documents that need to be translated.'),
        readMoreLink: ''
      },
      passwordDocument: {
        title: pgettext('helper', 'Password protection'),
        content: pgettext('helper', 'Please provide the password, if your files are protected.'),
        readMoreLink: ''
      },
      description: {
        title: pgettext('helper', 'Assignment description'),
        content: pgettext('helper', 'Extra information about the translation, if needed.'),
        readMoreLink: ''
      },
      participant: {
        title: pgettext('helper', 'Participant'),
        content: pgettext('helper', 'Do you need to send the job details to another participant who is not registered in Salita? You can add their email address here.'),
        readMoreLink: ''
      },
      sendPerPost: {
        title: pgettext('helper', 'Send per post'),
        content: pgettext('helper', 'Select this alternative, and enter your information, if you need the translated documents sent by post.'),
        readMoreLink: ''
      },
      accessLevel: {
        title: pgettext('helper', 'Access level'),
        content: pgettext('helper', 'Choose if this project should be accessible to just the booker, the department, or the entire enterprise.'),
        readMoreLink: ''
      }
    }
  };

  return postAssignmentHelper[context];
};
