<template>
  <article class="post-assignment-helper">
    <div class="post-assignment-helper__header">
      <span class="post-assignment-helper__icon"></span>
      <h5 class="post-assignment-helper__main-title">{{ $gettext("Help") }}</h5>
    </div>
    <hr class="post-assignment-helper__divider" />
    <div v-if="currentFocusedItem"
         class="post-assignment-helper__content-wrapper">
      <h6 v-if="helperData.title"
          class="post-assignment-helper__title">{{ helperData.title }}</h6>
      <p v-if="helperData.content"
         class="post-assignment-helper__content"
         v-html="helperData.content"></p>
      <a v-if="helperData.readMoreLink"
         class="sk-link"
         :href="helperData.readMoreLink">{{ $gettext('Read more') }}</a>
    </div>
    <div v-else
         class="post-assignment-helper__empty-state">
      <span class="post-assignment-helper__empty-state-icon"></span>
      <p class="post-assignment-helper__empty-state-text">{{ $gettext('Use the form on the left and get instant help.') }}</p>
    </div>
  </article>
</template>

<script>
  import {mapState} from 'vuex';
  import postAssignmentHelper from '@/modules/postAssignmentHelper';

  export default {
    computed: {
      ...mapState('PostAssignmentHelperStore', {
        currentFocusedItem: ({currentFocusedItem}) => currentFocusedItem,
        context: ({context}) => context || 'interpretation',
      }),
      helperData() {
        return postAssignmentHelper(this.$pgettext, this.context)[this.currentFocusedItem];
      }
    }
  };
</script>

<style>
.helper-margin-bottom {
  margin-bottom: 10px !important;
}
</style>

<style scoped>
.post-assignment-helper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  padding: 17px 24px 18px 25px;
  border-radius: 10px 10px 0 0;
  background: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}

#app.salita-facelifted .post-assignment-helper {
  border-radius: 8px;
  box-shadow: var(--shadow-and-blur);
}

.post-assignment-helper__header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.post-assignment-helper__main-title {
  font-weight: 700;
  font-size: 18px;
}

.post-assignment-helper__icon {
  width: 20px;
  height: 20px;
  background-image: var(--image-question-mark-icon-black);
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.post-assignment-helper__title {
  margin-bottom: 12px;
  color: var(--black-700);
  font-weight: 700;
  font-size: 14px;
}

.post-assignment-helper__content {
  margin-bottom: 12px;
  color: var(--black-700);
  font-weight: 400;
  font-size: 14px;
}

.post-assignment-helper__divider {
  margin-top: 16px;
  margin-bottom: 20px;
  border: 1px var(--cool-gray-200) solid;
}

.post-assignment-helper__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-assignment-helper__empty-state-icon {
  width: 64px;
  height: 64px;
  margin-top: 20px;
  margin-bottom: 18px;
  background-image: url(~@assets/imgs/undefined_imgs/empty_assignment_helper.svg);
  background-position: 50% 50%;
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.post-assignment-helper__empty-state-text {
  margin-bottom: 44px;
  color: var(--black-700);
  font-weight: 400;
  font-size: 14px;
}
</style>
