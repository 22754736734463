(<template>
  <post-accordion-section ref="messageToInterpreter"
                          :state="extraInfoSelectState"
                          :title="title"
                          :description="description"
                          class="extra-info"
                          @focusin="openMessageToInterpreterHelper"
                          @statechanged="changeExtraInfoSelectState">
    <div class="post-form__field-group extra-info__wrapper">
      <extra-info-section :params="params" />
    </div>
  </post-accordion-section>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';
  import PostAccordionSection from '@/components/booking_components/PostAccordionSection';
  import ExtraInfoSection from '@/components/booking_components/parts/extra_info_section/ExtraInfoSection';

  export default {
    components: {
      'post-accordion-section': PostAccordionSection,
      'extra-info-section': ExtraInfoSection
    },
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      ...mapState('PostingStore/ExtraInfoStore', {
        extraInfoSelectState: ({extraInfoSelectState}) => extraInfoSelectState
      }),
      title() {
        return this.$gettext('Message to interpreter');
      },
      description() {
        return this.$gettext('Provide the interpreter with details about the assignment before the start of the assignment to give more context about the assignment');
      },
      showAssignmentHelper() { return this.params.showAssignmentHelper; }
    },
    methods: {
      ...mapMutations('PostingStore/ExtraInfoStore', [
        'setExtraInfoSelectState',
        'setExtraInfo'
      ]),
      ...mapMutations('PostAssignmentHelperStore', [
        'openHelper',
        'setPopoverReference',
        'setReferenceToBuyerInfoSection',
      ]),
      openMessageToInterpreterHelper() {
        if (this.showAssignmentHelper) {
          this.setPopoverReference(this.$refs.messageToInterpreter);
          this.openHelper('messageToInterpreter');
        }
      },
      changeExtraInfoSelectState(value) {
        if (!value) {
          this.setReferenceToBuyerInfoSection();
          this.openHelper('');
        }
        this.setExtraInfoSelectState(value);
        this.setExtraInfo('');
      }
    }
  };
</script>

<style scoped>
  .extra-info__wrapper {
    margin-top: 20px;
  }
</style>
