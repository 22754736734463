<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import FullAddressValidationPrototype from '@/prototypes/FullAddressValidationPrototype';

  export default {
    extends: FullAddressValidationPrototype,
    computed: {
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userCanCreateJobs',
        'showSendByPostTranslation',
        'translationCoverLetterRequired',
        'userHasRequireOrderAttachment'
      ]),
      ...mapGetters('PostingStore/PaymentMethodStore', [
        'currentPaymentMethodData',
        'showCategoriesSection'
      ]),
      ...mapGetters('PostingNotifierStore', [
        'showAttendersSelect',
        'showDepartmentSelect'
      ]),
      ...mapState('PostingStore/AssignmentTypeStore', {
        videoPlatformType: ({videoPlatform}) => videoPlatform.type,
      }),
      ...mapState('PostingStore/SendMailStore', {
        name: (state) => state.name,
        company: (state) => state.company,
        isAddressInLetter: (state) => state.isAddressInLetter,
        address: (state) => state.address,
        sendByPost: (state) => state.sendByPost
      }),
      ...mapState('PostingStore/LanguagesStore', {
        nonLatinLanguage: (state) => state.nonLatinLanguage || '',
        noSourceLang: (state) => state.noSourceLang
      }),
      ...mapState('PostingStore/CoverLetterStore', {
        coverLetterAtached: (state) => state.coverLetterAtached
      }),
      ...mapGetters('PostingStore/CoverLetterStore', {
        coverLetters: 'attachments'
      }),
      ...mapGetters('PostingStore/DateTimeStore', ['minJobDuration']),
      subjectIsRequiredForCurrentCategory() {
        if (this.categoriesList.length) {
          for (const category of this.categoriesList) {
            if (category && category.items && category.items.length) {
              for (const subCategory of category.items) {
                if (subCategory.id == this.category) return subCategory.subjectRequired;
              }
            }
          }
        }
      },
      supportMode() { return this.$store.state.TTAuthStore.support; },
      userCanCreateEditJobs() { return this.userCanCreateJobs && this.userCanEditJob; },
      userCanEditJob() {
        return (this.jobStatus != 'cancelled'
          && this.jobStatus != 'closed')
          || this.$isGodmode();
      },
      videoroomJob() { return this.assignmentType === 'videoroom'; },
      minimalDurationErrorText() {
        const assignment = helpers.getJobInfo.jobType(this.assignmentType, this).toLowerCase() || this.$gettext('Default');
        const minJobDuration = helpers.getJobInfo.durationText(this.minJobDuration, this);
        const errorMessageTemplate = this.$gettext('Minimal duration for %{assignment} is %{minJobDuration}');

        return this.$gettextInterpolate(errorMessageTemplate, {assignment, minJobDuration});
      },
      allowBookingReference() { return this.currentPaymentMethodData.allowBookingReference; },
      allowPaymentBookingReference() { return this.currentPaymentMethodData.allowPaymentBookingReference; },
      requiredCaseNumberField() { return this.currentPaymentMethodData.allowCaseNumber && this.currentPaymentMethodData.requireCaseNumber; },
    },
    methods: {
      ...mapMutations('PostingStore/LanguagesStore', ['setNotLatinError']),
      ...mapActions('PostingStore/DepartmentAttendersStore', ['fetchDepartments']),
      ...mapActions({
        setPostingError: 'PostingStore/setError',
        removePostingErrors: 'PostingStore/removeErrors',
        removePostingOneError: 'PostingStore/removeOneError'
      }),
      ...mapMutations('PostingStore', ['resetValidationResult']),
      validateSubjectSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'SubjectStore'
        });
        if (this.categoriesList.length && this.showCategoriesSection) {
          if (!this.category) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'SubjectStore',
              fieldName: 'category',
              errorText: this.$gettext('Category is required.'),
              typeError: 'missingData'
            });
          } else if (this.subjectIsRequiredForCurrentCategory && !this.subject.trim()) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'SubjectStore',
              fieldName: 'subject',
              errorText: this.$gettext('Please add the assignment details here.'),
              typeError: 'missingData'
            });
          }
        } else if (!this.subject.trim() && this.showCategoriesSection) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'SubjectStore',
            fieldName: 'subject',
            errorText: this.$gettext('Subject is required.'),
            typeError: 'missingData'
          });
        }

        return isValidForm;
      },
      validateCoverLettersSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'CoverLetterStore'
        });
        if (this.sendByPost && this.translationCoverLetterRequired && !this.coverLetters.length && this.coverLetterAtached) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'CoverLetterStore',
            fieldName: 'attachments',
            errorText: this.$pgettext('translation', 'Please upload Cover letter.'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      validateSendMailSection() {
        let isValidForm = true;
        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'SendMailStore'
        });
        if (this.sendByPost && !this.name.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'SendMailStore',
            fieldName: 'name',
            errorText: this.$gettext('Name is required'),
            typeError: 'missingData'
          });
        }
        if (this.sendByPost && !this.company.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'SendMailStore',
            fieldName: 'company',
            errorText: this.$gettext('Company name is required'),
            typeError: 'missingData'
          });
        }
        if (this.sendByPost && !this.address.trim() && !this.isAddressInLetter) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'SendMailStore',
            fieldName: 'address',
            errorText: this.$gettext('Address is required'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      validateOwnerRealNameSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'OwnerRealNameStore'
        });
        if (this.showOwnerRealNameSection && !this.ownerRealName.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'OwnerRealNameStore',
            fieldName: 'ownerRealName',
            errorText: this.$gettext('Real name is required.'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      validatePaymentMethodSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'PaymentMethodStore'
        });
        if (!this.currentPaymentMethodData.id) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'PaymentMethodStore',
            fieldName: 'paymentMethod',
            errorText: this.$gettext('Please add payment method or choose from an existing one'),
            typeError: 'missingData'
          });
        }

        return isValidForm;
      },
      validateBookingReferenceSection() {
        let isValidForm = true;
        const errorTemplate = this.$gettext('At least one reference is required.');
        const isReferencesEmpty = (!this.paymentBookingRef && !this.paymentBookingRef?.trim()) && (!this.bookingRef && !this.bookingRef.trim());

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'BookingRefStore'
        });
        if (this.allowPaymentBookingReference) {
          const labelPaymentRef = this.currentPaymentMethodData.labelForPaymentBookingReference || this.$gettext('Attestant BID');
          if (isReferencesEmpty) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'BookingRefStore',
              fieldName: 'paymentBookingRef',
              errorText: this.$gettextInterpolate(errorTemplate, {labelPaymentRef}),
              typeError: 'missingData'
            });
          }
        }
        if (this.allowBookingReference) {
          const labelBookingRef = this.currentPaymentMethodData.labelForBookingReference || this.$gettext('Booking ref.');
          if (isReferencesEmpty) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'BookingRefStore',
              fieldName: 'bookingRef',
              errorText: this.$gettextInterpolate(errorTemplate, {labelBookingRef}),
              typeError: 'missingData'
            });
          }
        }

        return isValidForm;
      },
      validateAttachmentsSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'AttachmentsStore'
        });
        if (!this.attachments.length) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'AttachmentsStore',
            fieldName: 'attachments',
            errorText: this.$gettext('Please upload at least one file.'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      validateAttachedFiles({maxSize, maxFiles, fileIsRequired = true} = {}) {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'AttachmentsStore'
        });

        if (fileIsRequired && !this.attachments.length) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'AttachmentsStore',
            fieldName: 'attachments',
            errorText: this.$gettext('Please upload at least one file.'),
            typeError: 'missingData'
          });
        }
        if (this.attachments.length && this.attachments.length > maxFiles) {
          isValidForm = false;
          const temp = this.$gettext('Maximum allowed number of files is %{maxFiles}');
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'AttachmentsStore',
            fieldName: 'attachments',
            errorText: this.$gettextInterpolate(temp, {maxFiles}),
            typeError: 'missingData'
          });
        }
        if (this.attachments.length) {
          this.attachments.forEach((attachment) => {
            // byte to MegaByte
            const maxSizeMB = maxSize * 1048576;
            if (attachment.file.size >= maxSizeMB) {
              isValidForm = false;
              const temp = this.$gettext('Maximum allowed file size is %{maxSize}MB');
              this.setPostingError({
                storeName: 'PostingStore',
                subStoreName: 'AttachmentsStore',
                fieldName: 'attachments',
                errorText: this.$gettextInterpolate(temp, {maxSize}),
                typeError: 'wrongData'
              });
            }
          });
          this.checkDuplicateFiles(this.attachments);
        }
        return isValidForm;
      },
      checkDuplicateFiles(attachments) {
        const uploadedFileNames = attachments.map((attachment) => attachment.name);
        const isDuplicateFile = uploadedFileNames.some((fileName, index) => {
          return uploadedFileNames.indexOf(fileName) !== index;
        });
        if (isDuplicateFile) {
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'AttachmentsStore',
            fieldName: 'attachments',
            errorText: this.$gettext('Please remove the duplicate files selected.'),
            typeError: 'wrongData'
          });
        }
      },
      validateProcessingSections() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'ProcessRequirementStore'
        });
        if (this.showSessionTypeProcessingSection && !this.sessionTypeProcessing) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ProcessRequirementStore',
            fieldName: 'sessionTypeProcessing',
            errorText: this.$gettext('Please choose one option'),
            typeError: 'missingData'
          });
        }
        if (this.showDirectProcessingSection && !this.directProcessing) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ProcessRequirementStore',
            fieldName: 'directProcessing',
            errorText: this.$gettext('Please choose one option'),
            typeError: 'missingData'
          });
        }
        if (this.showGenderProcessingSection && !this.genderProcessing) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ProcessRequirementStore',
            fieldName: 'genderProcessing',
            errorText: this.$gettext('Please choose one option'),
            typeError: 'missingData'
          });
        }
        if (this.showQualificationProcessingSection && !this.qualificationProcessing) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ProcessRequirementStore',
            fieldName: 'qualificationProcessing',
            errorText: this.$gettext('Please choose one option'),
            typeError: 'missingData'
          });
        }
        if (this.showSeveralSuppliersProcessingSection && !this.severalSuppliersProcessing) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ProcessRequirementStore',
            fieldName: 'severalSuppliersProcessing',
            errorText: this.$gettext('Please choose one option'),
            typeError: 'missingData'
          });
        }

        return isValidForm;
      },
      validateDepartmentAttendersSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'DepartmentAttendersStore'
        });
        if (this.showDepartmentSelect && !this.department) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'DepartmentAttendersStore',
            fieldName: 'department',
            errorText: this.showDepartmentSelect ? this.$gettext('Please choose a department') : this.$gettext('Please add a department'),
            typeError: 'missingData'
          });
        }

        return isValidForm;
      },
      validateCaseNumberSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'CaseNumberStore'
        });
        if (this.requiredCaseNumberField) {
          const label = this.currentPaymentMethodData.labelForCaseNumber || this.$gettext('Case number');

          if (!this.caseNumber && !this.caseNumber.trim()) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'CaseNumberStore',
              fieldName: 'caseNumber',
              errorText: this.$gettextInterpolate(this.$gettext('%{label} is required.'), {label}),
              typeError: 'missingData'
            });
          }
        }

        return isValidForm;
      },
      validateDeadlineSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'DeadlineStore'
        });
        if (this.$moment().isAfter(this.deadline) && !this.$isGodmode()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'DeadlineStore',
            fieldName: 'deadlineTime',
            errorText: this.$gettext('Deadline time can\'t be in the past'),
            typeError: 'wrongData'
          });
        }

        return isValidForm;
      },
      validateConfirmationBySection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'ConfirmationByStore'
        });
        if (this.confirmationBy) {
          if (this.$moment().isAfter(this.confirmationBy)) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'ConfirmationByStore',
              fieldName: 'deadlineTime',
              errorText: this.$gettext('Confirmation required by date can\'t be in the past'),
              typeError: 'wrongData'
            });
          } else if (this.$moment(this.confirmationBy).isAfter(this.fullStartTime)) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'ConfirmationByStore',
              fieldName: 'deadlineTime',
              errorText: this.$gettext('Confirmation required by date can\'t be later than the start time'),
              typeError: 'wrongData'
            });
          }
        }

        return isValidForm;
      },
      validateLanguagesSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'LanguagesStore'
        });
        if (this.languageFrom
          && this.targetLanguages.length
          && this.targetLanguages.includes(this.languageFrom)) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'LanguagesStore',
            fieldName: 'langTo',
            errorText: this.$gettext('Target language must be different from your language.'),
            typeError: 'wrongData'
          });
        }
        if (this.showAlternativeLanguageSelect
          && this.languageFrom
          && this.languageTo
          && this.languageFrom != this.languageTo
          && this.alternativeLanguageTo == this.languageTo) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'LanguagesStore',
            fieldName: 'alternativeLangTo',
            errorText: this.$gettext('An alternative language must be different from target language.'),
            typeError: 'wrongData'
          });
        }
        if (!this.languageFrom) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'LanguagesStore',
            fieldName: 'langFrom',
            errorText: this.$gettext('Your language is required.'),
            typeError: 'missingData'
          });
        }
        if (!this.languageTo) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'LanguagesStore',
            fieldName: 'langTo',
            errorText: this.$gettext('Target language is required.'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      validateSourceTargetLanguagesSection() {
        let isValidForm = true;
        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'LanguagesStore'
        });
        if (!this.languageFrom && !this.noSourceLang) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'LanguagesStore',
            fieldName: 'langFrom',
            errorText: this.$pgettext('translation', 'Please provide the language for the original document.'),
            typeError: 'missingData'
          });
        }
        if (this.targetLanguages == [] || this.targetLanguages.length == 0) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'LanguagesStore',
            fieldName: 'targetLangs',
            errorText: this.$pgettext('translation', 'Please provide at least one language that we should translate to.'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      validateNonLatinLang() {
        let isValidForm = true;

        if (this.nonLatinLanguage) {
          isValidForm = false;
          this.setNotLatinError(true);
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'LanguagesStore',
            fieldName: 'nonLatinLang',
            errorText: 'Required',
            typeError: 'wrongData'
          });
        }

        return isValidForm;
      },
      validateOverlappingMultipleDateTime() {
        let isValidFrom = true;
        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'MultipleDateTimeStore'
        });

        if (this.dateTimes.length) {
          const dateTimesList = [
            ...this.dateTimes,
            ...[{startTime: this.$moment(this.fullStartTime), finishTime: this.$moment(this.fullFinishTime)}]
          ];
          let isOverlapped = false;

          dateTimesList.forEach((dateTime1, index1) => {
            const {startTime: startTime1, finishTime: finishTime1} = dateTime1;

            dateTimesList.forEach((dateTime2, index2) => {
              const {startTime: startTime2, finishTime: finishTime2} = dateTime2;
              const isOverlappedStartTime = startTime1.isBetween(startTime2, finishTime2, null, '[]');
              const isOverlappedFinishTime = finishTime1.isBetween(startTime2, finishTime2, null, '[]');

              if ((isOverlappedStartTime || isOverlappedFinishTime) && index1 !== index2) {
                isOverlapped = true;
              }
            });
          });

          if (isOverlapped) {
            isValidFrom = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'MultipleDateTimeStore',
              fieldName: 'dateTimesOverlapping',
              errorText: this.$gettext('Some dates overlap. Please change the assignment time.'),
              typeError: 'wrongData'
            });
          }
        }

        return isValidFrom;
      },
      validateMultipleDateTimeSection() {
        let isValidFrom = true;
        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'MultipleDateTimeStore'
        });
        if (this.dateTimes.length) {
          this.dateTimes.forEach((dateTime, index) => {
            const startTime = this.$moment(dateTime.startTime);
            const finishTime = this.$moment(dateTime.finishTime);
            if (this.$moment().isAfter(startTime) && !this.supportMode) {
              isValidFrom = false;
              this.setPostingError({
                storeName: 'PostingStore',
                subStoreName: 'MultipleDateTimeStore',
                fieldName: `startTime${index}`,
                errorText: this.$gettext('Assignment can\'t start in the past'),
                typeError: 'wrongData'
              });
            } else if (finishTime.diff(startTime, 'minutes') < this.minJobDuration) {
              isValidFrom = false;
              this.setPostingError({
                storeName: 'PostingStore',
                subStoreName: 'MultipleDateTimeStore',
                fieldName: `finishTime${index}`,
                errorText: this.minimalDurationErrorText,
                typeError: 'wrongData'
              });
            }
          });
        }
        if (this.seriesJobProcessing === 'same_int') {
          isValidFrom = this.validateOverlappingMultipleDateTime();
        }
        return isValidFrom;
      },
      validateDateTimeSection() {
        let isValidFrom = true;
        const startTime = this.$moment(this.fullStartTime);
        const finishTime = this.$moment(this.fullFinishTime);

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'DateTimeStore'
        });
        if (!this.shouldBeDisabledImportantFields && !this.$isGodmode()) {
          if (!finishTime.date()) {
            isValidFrom = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'DateTimeStore',
              fieldName: 'finishTime',
              errorText: this.$gettext('Finish time is required'),
              typeError: 'missingData'
            });
          } else if (finishTime.isBefore(startTime)) {
            isValidFrom = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'DateTimeStore',
              fieldName: 'finishTime',
              errorText: this.$gettext('Assignment can\'t finish before start time.'),
              typeError: 'wrongData'
            });
          } else if (finishTime.diff(startTime, 'minutes') < this.minJobDuration) {
            isValidFrom = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'DateTimeStore',
              fieldName: 'finishTime',
              errorText: this.minimalDurationErrorText,
              typeError: 'wrongData'
            });
          } else if (finishTime.diff(startTime, 'minutes') > 24 * 60) {
            isValidFrom = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'DateTimeStore',
              fieldName: 'finishTime',
              errorText: this.$gettext('Maximum assignment duration is 24 hours'),
              typeError: 'wrongData'
            });
          }
          if (!startTime.date()) {
            isValidFrom = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'DateTimeStore',
              fieldName: 'startTime',
              errorText: this.$gettext('Start time is required'),
              typeError: 'missingData'
            });
          } else if (this.$moment().isAfter(startTime) && !this.supportMode) {
            isValidFrom = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'DateTimeStore',
              fieldName: 'startTime',
              errorText: this.$gettext('Assignment can\'t start in the past'),
              typeError: 'wrongData'
            });
          }
        }

        return isValidFrom;
      },
      validateVideoSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'AssignmentTypeStore'
        });

        if (this.videoPlatformType === 'own') {
          if (!this.videoPlatformId) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'AssignmentTypeStore',
              fieldName: 'videoPlatformId',
              errorText: this.$gettext('Please choose video platform.'),
              typeError: 'missingData'
            });
          }
          if (!this.videoPlatformConfirmationValue) {
            isValidForm = false;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'AssignmentTypeStore',
              fieldName: 'videoPlatformConfirmationValue',
              errorText: this.$gettext('Please confirm that you will provide a video link and instructions to the interpreter'),
              typeError: 'missingData'
            });
          }
        }

        return isValidForm;
      },
      validateAddressSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'AssignmentTypeStore'
        });
        this.removePostingErrors({storeName: 'FullAddressStore'});
        if (!this.contactPersonName.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'AssignmentTypeStore',
            fieldName: 'personName',
            errorText: this.$gettext('Contact person name is required.'),
            typeError: 'missingData'
          });
        }
        if (!this.contactPersonPhone.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'AssignmentTypeStore',
            fieldName: 'personPhone',
            errorText: this.$gettext('Contact person number is required.'),
            typeError: 'missingData'
          });
        }
        if (!this.isAddressSelectedFromList) {
          if (!this.validateAddressForm()) {
            isValidForm = false;
          }
        }
        return isValidForm;
      },
      validateAssignmentTypeSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'AssignmentTypeStore'
        });
        this.removePostingErrors({storeName: 'FullAddressStore'});
        if (!this.allowedAssignmentTypes.length) {
          isValidForm = false;
        } else if (!this.assignmentType) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'AssignmentTypeStore',
            fieldName: 'assignmentType',
            errorText: this.$gettext('Please select assignment type'),
            typeError: 'missingData'
          });
        } else if (this.assignmentType === 'in_person') {
          if (this.videoPlatformType === 'own') {
            if (!this.validateVideoSection()) {
              isValidForm = false;
            }
          }
          if (!this.validateAddressSection()) {
            isValidForm = false;
          }
        } else if (this.assignmentType === 'video') {
          if (!this.validateVideoSection()) {
            isValidForm = false;
          }
        }

        return isValidForm;
      },
      validateParticipantsSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'ParticipantsStore'
        });
        if (this.participantEmail.trim()) {
          isValidForm = false;
          this.$store.dispatch('PostingStore/ParticipantsStore/catchParticipantsSelect', true);
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ParticipantsStore',
            fieldName: 'participantEmail',
            errorText: this.$gettext('Please click "Add" to add this email, or <button class="js-clearing-email post-form__clearing-btn">clear this field</button>'),
            typeError: 'wrongData'
          });
          setTimeout(() => {
            const clearBtn = document.querySelector('.js-clearing-email');
            if (clearBtn) { clearBtn.addEventListener('click', this.clearParticipantEmail); }
          });
        }
        if (this.participantPhone.trim()) {
          isValidForm = false;
          this.$store.dispatch('PostingStore/ParticipantsStore/catchParticipantsSelect', true);
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ParticipantsStore',
            fieldName: 'participantPhone',
            errorText: this.$gettext('Please click "Add" to add this phone, or <button class="js-clearing-phone post-form__clearing-btn">clear this field</button>'),
            typeError: 'wrongData'
          });
          setTimeout(() => {
            const clearBtn = document.querySelector('.js-clearing-phone');
            if (clearBtn) { clearBtn.addEventListener('click', this.clearParticipantPhone); }
          });
        }

        return isValidForm;
      },
      validateSupplierAvailabilitySection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'DirectBookingStore'
        });
        if (this.directBookingSelected && this.selectedSupplier && this.hasOverlappedEvents) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'DirectBookingStore',
            fieldName: 'availabilityError',
            errorText: this.$gettext('The selected interpreter is busy during the time you selected. Please change the assignment time.'),
            typeError: 'wrongData'
          });
        }
        return isValidForm;
      },
      validateReplacementSection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'ReplacementStore'
        });
        if (this.showReplacementSection && !this.actualCreatedAt) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'ReplacementStore',
            fieldName: 'actualCreatedAt',
            errorText: this.$gettext('Order time is required.'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      validateSecretarySection() {
        let isValidForm = true;

        this.removePostingErrors({
          storeName: 'PostingStore',
          subStoreName: 'SecretaryStore'
        });
        if (!this.secretaryContactName.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'SecretaryStore',
            fieldName: 'secretaryContactName',
            errorText: this.$gettext('Name is required.'),
            typeError: 'missingData'
          });
        }
        if (!this.secretaryContactPhone.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'SecretaryStore',
            fieldName: 'secretaryContactPhone',
            errorText: this.$gettext('Phone is required.'),
            typeError: 'missingData'
          });
        }
        if (!this.secretaryInfo.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'SecretaryStore',
            fieldName: 'secretaryInfo',
            errorText: this.$gettext('Information for the interpreter is required.'),
            typeError: 'missingData'
          });
        }
        if (this.secretaryCallbackPhoneSelect && !this.secretaryCallbackPhone.trim()) {
          isValidForm = false;
          this.setPostingError({
            storeName: 'PostingStore',
            subStoreName: 'SecretaryStore',
            fieldName: 'secretaryCallbackPhone',
            errorText: this.$gettext('Phone is required.'),
            typeError: 'missingData'
          });
        }
        return isValidForm;
      },
      clearParticipantEmail(event) {
        event.preventDefault();
        this.removePostingOneError({
          storeName: 'PostingStore',
          subStoreName: 'ParticipantsStore',
          fieldName: 'participantEmail'
        });
        this.$store.commit('PostingStore/ParticipantsStore/setInviteEmail', '');
      },
      clearParticipantPhone(event) {
        event.preventDefault();
        this.removePostingOneError({
          storeName: 'PostingStore',
          subStoreName: 'ParticipantsStore',
          fieldName: 'participantPhone'
        });
        this.$store.commit('PostingStore/ParticipantsStore/setParticipantPhone', '');
      }
    }
  };
</script>
