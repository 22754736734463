(<template>
  <article class="select-category-modal sk-modal__wrapper">
    <section class="select-category-modal__section--search">
      <h2 class="select-category-modal__title">{{ selectCategoryText }}</h2>
      <sk-input ref="search"
                :placeholder="searchCategoryText"
                :preselected-value="searchQuery"
                :icon="'search'"
                :autofocus="true"
                :reg-exp="/<\/?[^>]+(>|$)/g"
                class="select-category-modal__input"
                @fieldvaluechanged="setSearchCategoryQuery" />
    </section>
    <section v-if="showNoResultsSection"
             class="select-category-modal__section">
      <h3 class="select-category-modal__subtitle">{{ searchQueryNoResultsText }}</h3>
    </section>
    <section class="select-category-modal__section">
      <div v-for="category in filteredCategoryList"
           :key="category.id"
           class="category-wrapper">
        <h4 class="category__subtitle">{{ category.name }}</h4>
        <ul class="category__list">
          <li v-for="item in category.items"
              :key="item.id"
              :class="item.id === categoryId ? 'category__list--active': ''"
              class="category__item"
              @click="handleSetCategory(item.id)">
            <span class="category__item--name">{{ item.name }}</span>
            <span class="category__item--description">{{ item.description }}</span>
            <span v-if="item.id === categoryId"
                  class="category__selected-icon"></span>
          </li>
        </ul>
      </div>
    </section>
    <section class="select-category-modal__section select-category-modal__section--disclaimer">
      <p class="select-category-modal__disclaimer-text">
        {{ disclaimerText }}
      </p>
    </section>
  </article>
</template>)

<script>
  import {mapState, mapMutations} from 'vuex';

  export default {
    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        searchQuery: ''
      };
    },
    computed: {
      ...mapState('PostingStore/SubjectStore', {
        categoryId: (state) => state.category,
      }),
      categoryList() { return this.data.list; },
      selectCategoryText() { return this.$gettext('Select category'); },
      searchCategoryText() { return this.$gettext('Search for category'); },
      showNoResultsSection() { return !this.filteredCategoryList.length && this.searchQuery; },
      searchQueryNoResultsText() {
        const template = this.$gettext('No results for \"%{query}\"');
        return this.$gettextInterpolate(template, {query: this.searchQuery});
      },
      filteredCategoryList() {
        if (!this.searchQuery) {
          return this.categoryList;
        }

        const query = this.searchQuery.toLowerCase();

        return this.categoryList
          .map((category) => ({
            ...category,
            items: category.items.filter((item) => item.name.toLowerCase().includes(query) || (item.description && item.description.toLowerCase().includes(query))),
          })).filter((category) => category.items.length > 0);
      },
      disclaimerText() {
        return this.$gettext('Disclaimer: These category descriptions are intended as a helpful guide for choosing the appropriate subject for interpreter bookings. It is the responsibility of the booker to ensure the accuracy of the selected category and subject. Salita is not liable for any consequences arising from incorrect or inappropriate categorization.');
      }
    },
    watch: {
      $route() {
        this.closeModal();
      }
    },
    methods: {
      ...mapMutations('PostingStore/SubjectStore', ['setCategory']),
      closeModal() {
        this.$emit('closemodal');
      },
      setSearchCategoryQuery(query) {
        this.searchQuery = query.trim();
      },
      handleSetCategory(categoryId) {
        this.setCategory(categoryId);
        this.closeModal();
      }
    },
    mounted() {
      const input = document.querySelector('.sk-input__input');
      setTimeout(() => {
        input.focus();
      }, 1000);
    }
  };
</script>

<style>
.select-category-modal__input .sk-input__input-wrapper .sk-input__input {
  margin-bottom: 0;
  border-radius: 4px;
}
</style>

<style scoped>
.select-category-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  margin-top: 20px;
  padding: 0 40px;
}

.select-category-modal__title {
  margin-bottom: 15px;
  color: #000;
  font-size: 20px;
}

.select-category-modal__subtitle {
  margin-top: 16px;
  margin-bottom: 16px;
  color: #000;
  font-size: 14px;
}

.select-category-modal__section {
  position: relative;
  padding-bottom: 16px;
}

.select-category-modal__section--search {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--col-white);
}

@media (max-width: 767px) {
  .select-category-modal {
    height: auto;
    padding: 0 20px;
  }
}

.category-wrapper {
  margin: 24px 0;
}

.category__subtitle {
  margin-bottom: 16px;
  color: var(--black-700);
}

.category__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  width: 100%;
  list-style-type: none;
}

.category__item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: var(--col-white);
  box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
  cursor: pointer;
}

.category__list--active {
  border-color: var(--color-black);
}

.category__item--name {
  font-weight: bold;
}

.category__item--description {
  color: var(--black-500);
}

.category__selected-icon {
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 16px;
  height: 16px;
  background-image: url(~@assets/imgs/undefined_imgs/selected_payment_icon_black.svg);
}

.select-category-modal__section--disclaimer {
  padding-top: 12px;
  border-top: 1px solid var(--black-100);
  margin-top: auto;
}

.select-category-modal__disclaimer-text {
  font-size: 12px;
  color: var(--black-500);
  line-height: 1.5;
}
</style>
